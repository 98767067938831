import {
  AimOutlined,
  BranchesOutlined,
  BuildOutlined, CarOutlined,
  DashboardOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  ClusterOutlined,
  IssuesCloseOutlined,
  UserOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { HOME_PREFIX_PATH } from '../constants/common';

const mainNavTree = [
  {
    key: 'dashboard',
    path: `${HOME_PREFIX_PATH}/dashboard`,
    title: 'Dashboard',
    icon: DashboardOutlined,
    permission: 'dashboard_menu_web',
    breadcrumb: false,
  },
  {
    key: 'leads',
    path: `${HOME_PREFIX_PATH}/leads`,
    title: 'All Leads',
    icon: BuildOutlined,
    permission: 'leads_menu_web',
    breadcrumb: false,
  },
  {
    key: 'fi-tasks',
    path: `${HOME_PREFIX_PATH}/fi-tasks`,
    title: 'External FI Tasks',
    icon: BranchesOutlined,
    permission: 'fi_task_menu_web',
    breadcrumb: false,
  },
  {
    key: 'fi_internal',
    path: `${HOME_PREFIX_PATH}/fi-internal`,
    title: 'Internal FI Tasks',
    icon: ClusterOutlined,
    permission: 'valuation_menu_web',
    breadcrumb: false,
  },
  // {
  //   key: 'dsa',
  //   path: `${HOME_PREFIX_PATH}/dsa`,
  //   title: 'DSA',
  //   icon: UserOutlined,
  //   permission: 'valuation_menu_web',
  //   breadcrumb: false,
  // },
  {
    key: 'gps-devices',
    path: `${HOME_PREFIX_PATH}/gps-devices`,
    title: 'GPS Devices',
    icon: AimOutlined,
    permission: 'gps_task_menu_web',
    breadcrumb: false,
  },
  {
    key: 'valuations',
    path: `${HOME_PREFIX_PATH}/valuations`,
    title: 'Valuations',
    icon: CarOutlined,
    permission: 'valuation_menu_web',
    breadcrumb: false,
  },
  {
    key: 'reports',
    path: `${HOME_PREFIX_PATH}/reports`,
    title: 'MIS',
    icon: FileTextOutlined,
    permission: 'mis_menu_web',
    breadcrumb: false,
  },
  {
    key: 'lead-approval',
    path: `${HOME_PREFIX_PATH}/lead-approval`,
    title: 'Lead Approval',
    icon: IssuesCloseOutlined,
    permission: 'mis_menu_web',
    breadcrumb: false,
  },
  // {
  //   key: 'users',
  //   path: `${HOME_PREFIX_PATH}/users`,
  //   title: 'Master Administrator',
  //   icon: TeamOutlined,
  //   breadcrumb: true,
  //   permission: 'users_menu_web',
  // },
];

// const extraNavTree = [
//   {
//     key: 'extra',
//     // path: `${HOME_PREFIX_PATH}/extra`,
//     title: 'Extra',
//     icon: AntDesignOutlined,
//     breadcrumb: true,
//     permission: 'users_menu_web',
//     submenu: [
//       {
//         key: 'extra-branches',
//         path: `${HOME_PREFIX_PATH}/branches`,
//         title: 'Branches',
//         icon: AimOutlined,
//         breadcrumb: true,
//         permission: 'branch_menu_web',
//         submenu: [],
//       },
//       {
//         key: 'extra-users',
//         path: `${HOME_PREFIX_PATH}/users`,
//         title: 'Users',
//         icon: TeamOutlined,
//         breadcrumb: true,
//         permission: 'users_menu_web',
//         submenu: [],
//       },
//     ],
//   },
// ];

const navigationConfig = [
  ...mainNavTree,
];

export default navigationConfig;
